.scrollTopBtn {
    @include size(40px, 45px);
    @include position(fixed, $bottom: 20px, $right: 20px);
    position: fixed;
    transform: translateY(100px);
    z-index: 100;
    background: $color-main;
    border-color: $color-main;
    border-radius: 50px 50px 0 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: all .5s ease;

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        width: 100%;
        height: 100%;
        fill: $color-text;
        transition: $duration;
    }

    &:hover,
    &:focus {
        background: $color-second;
        border-color: $color-second;

        svg {
            fill: $color-dark;
        }
    }
}