//======================================================================================================
// SlideShow
//======================================================================================================

.sticky {
    position: fixed;
    z-index: 10;
    right: 0;
    top: 365px;
    
    &__container {
        @include flex($direction: column, $alignItems: flex-end);
        gap: 15px;
    }

    &__link {
        @include size(225px, 55px);
        @include flex($alignItems: center);
        background-color: $color-second;
        gap: 5px;
        border-radius: 50px 0px 0px 50px;
        width: 70px;
        padding-left: 20px;
        transition: all $duration ease-in-out;

        svg {
            @include size(40px);
            min-width: 40px;
            fill: $color-dark;
            transition: all $duration ease-in-out;
        }

        p {
            color: $color-dark;
            font-family: $font-family--heading;
            font-size: toRem(16);
            font-weight: 700;
            line-height: toRem(20);
            letter-spacing: 0.8px;
            text-transform: uppercase;
            min-width: 140px;
            opacity: 0;
            transition: opacity $duration ease-in-out;
        }

        &--visible {
            width: 225px;

            p {
                opacity: 1;
            }

            &.hidden {
                width: 70px;

                p {
                    opacity: 0;
                }
            }
        }

        &:hover, &:focus {
            background-color: $color-main;
            width: 225px;

            p {
                opacity: 1;
            }
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================

.home-slideshow {

    > .container {
        position: relative;
    }

    .container {
        &--pagination {
            @include absolute(0, 0, 0, 0);
            height: 100%;
        }
    }

    .slideshow {
        @include size(100%, 640px);
        position: relative;

        // &:before {
        //     @include position(absolute, $top: 0, $left: 0);
        //     content: "";
        //     @include size(100%);
        //     background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.35) 47.92%, rgba(0, 0, 0, 0.00) 100%);
        //     z-index: 2;
        //     transition: all duration ease-in-out;
        // }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            @include size(100%);
        }

        &__container {
            display: inherit;
            position: relative;
            @include size(100%);
        }

        &--video {
            img {
                display: none;
            }
        }

        &__pagination {
            @include absolute($bottom: 20px, $left: 15px);
            @include flex($alignItems: center, $justifyContent: center);
            z-index: 2;
            width: auto;
            gap: 5px;
    
            .swiper-pagination-bullet {
                @include size(12px);
                background-color: $color-third;
                border: 1px solid $color-third;
                border-radius: 50%;
                opacity: 1;
                transition: all $duration ease-in-out;
    
                &:hover, &:focus, &-active {
                    background-color: transparent;
                    border-color: $color-white;
                }
            }
        }
    }
}

//======================================================================================================
// ACTUALITES
//======================================================================================================

.news {
    position: relative;
    padding: 100px 0;

    &:before, &:after {
        position: absolute;
        content: "";
        background-repeat: no-repeat;
        z-index: -1;
        transition: all $duration ease-in-out;
    }

    &:before {
        @include size(420px, 695px);
        background-image: url(/wp-content/themes/la-grande-motte-port/assets/src/images/sprite-images/home/bg_cata.svg);
        left: calc(50% + 490px);
        top: -100px;
    }

    &:after {
        @include size(715px, 237px);
        background-image: url(/wp-content/themes/la-grande-motte-port/assets/src/images/sprite-images/home/bg_actus.svg);
        left: calc(50% - 1105px);
        bottom: 0;
    }

    &__container {
        @include flex($direction: column, $alignItems: flex-start);
    }

    &__header {
        @include flex($direction: column);
    }

    &__title {
        @extend %homeTitle;
    }

    &__subtitle {
        @extend %homeSubtitle;
        margin-left: 50px;
    }

    &__content {
        @include flex($direction: column);
        row-gap: 30px;
        margin-top: 30px;
    }

    &__link {
        &--big {
            @include flex($alignItems: flex-start);
            column-gap: 40px;

            &:hover, &:focus {
                .news__category--big::before {
                    width: 100%;
                }
            }
        }

        &--small {
            width: 400px;
            @include flex($direction: column, $alignItems: flex-start);
            row-gap: 10px;

            &:hover, &:focus {
                .news__category--small {
                    padding-left: 20px;
                    background-color: $color-second;
                    color: $color-dark;
                }
            }
        }

        &:hover, &:focus {
            .news__image img {
                transform: scale(1.1);
            }
        }
    }

    &__image {
        overflow: hidden;

        &--big {
            @include size(700px, 320px);
        }

        &--small {
            @include size(100%, 250px);
            position: relative;
        }

        img {
            @include size(100%);
            object-fit: cover;
            transition: $duration;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light;
        background-position: center;

        &--big {
            @include size(700px, 320px);
        }

        &--small {
            @include size(100%, 250px);
            position: relative;
        }
    }

    &__infos {
        @include flex($direction: column, $alignItems: flex-start);
        color: $color-dark;

        &--big {
            width: 315px;
            margin-top: 45px;
        }
    }

    &__category {
        font-family: $font-family;
        font-weight: 400;
        text-transform: uppercase;
        transition: all $duration ease-in-out;

        &--big {
            font-size: toRm(18);
            line-height: toRem(25);
            letter-spacing: 0.9px;
            position: relative;

            &:before {
                @include position(absolute, $left: 0, $bottom: -5px);
                @include size(0, 5px);
                content: "";
                background-color: $color-third;
                border-radius: 0px 20px 20px 0px;
                z-index: 0;
                transition: all $duration ease-in-out;
            }
        }

        &--small {
            font-size: toRem(14);
            line-height: toRem(20);
            letter-spacing: 1.4px;
            color: $color-white;
            background-color: $color-dark;
            padding: 5px 15px 5px 10px;
            border-radius: 0px 20px 20px 0px;
            @include position(absolute, $left: 0, $bottom: 20px);
        }
    }

    &__titlep {
        @extend %removeMargin;
        font-family: $font-family--heading;
        font-weight: 700;

        &--big {
            font-size: toRem(30);
            line-height: toRem(35);
            margin: 10px 0;
        }

        &--small {
            font-size: toRem(23);
            line-height: toRem(30);
        }
    }

    &__text {
        &--small {
            margin-top: 7px;
        }
    }

    &__bottom {
        @include flex($direction: row-reverse, $alignItems: flex-start);
        gap: 30px;
    }

    &__smallitems {
        @include flex($justifyContent: flex-end);
        column-gap: 30px;
    }

    &__buttons {
        @include flex($direction: column);
        gap: 15px;
    }

    &__button {
        @extend %button;
        width: 300px;
    }
}

//======================================================================================================
// Escale
//======================================================================================================

.escale {
    background-color: $color-dark;
    color: $color-white;
    padding: 100px 0 95px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        @include size(535px, 590px);
        background-image: url(/wp-content/themes/la-grande-motte-port/assets/src/images/sprite-images/home/bg_voilier.svg);
        left: calc(50% + -875px);
        bottom: -10px;
        background-repeat: no-repeat;
        z-index: 0;
        transition: all $duration ease-in-out;
    }

    &__container {
        display: flex;
    }

    &__left {
        @include flex($direction: column);
        gap: 25px;
        min-width: 670px;
        position: relative;
        z-index: 1;
    }

    &__bottom {
        @include flex($direction: row-reverse, $alignItems: flex-start);
        gap: 30px;
    }

    &__header {
        @include flex($direction: column);
        margin-top: 40px;
    }

    &__title {
        @extend %homeTitle;
        margin-left: 25px;
    }

    &__subtitle {
        @extend %homeSubtitle;
    }

    &__text {
        font-size: toRem(18);
        font-weight: 400;
        line-height: toRem(25);
        width: 370px;
        margin: 120px 0 0 100px;
        position: relative;

        &:before {
            @include position(absolute, $top: -60px, $left: -160px);
            @include size(950px, 345px);
            content: "";
            background-color: rgba($color-light, 0.1);
            border-radius: 350px 0px 0px 350px;
            z-index: 0;
            transition: all $duration ease-in-out;
        }
    }

    &__button {
        @extend %button;
        color: $color-white;
        width: 300px;
        border-color: $color-second;

        &::before,
        &::after {
            background-color: $color-second;
        }

        &:hover,
        &:focus {
            color: $color-white;
            border-color: $color-main;
        }
    }
}

//======================================================================================================
// Agenda - Quoi faire à la Grande Motte
//======================================================================================================

.home-events {
    padding: 98px 0 100px;
    position: relative;

    &:before, &:after {
        position: absolute;
        content: "";
        background-repeat: no-repeat;
        z-index: -1;
        transition: all $duration ease-in-out;
    }

    &:before {
        @include size(450px, 610px);
        background-image: url(/wp-content/themes/la-grande-motte-port/assets/src/images/sprite-images/home/bg_lgm_droite.svg);
        left: calc(50% + 360px);
        top: 0;
    }

    &:after {
        @include size(960px, 408px);
        background-image: url(/wp-content/themes/la-grande-motte-port/assets/src/images/sprite-images/home/bg_lgm_gauche.svg);
        left: calc(50% - 1115px);
        bottom: -8px;
    }

    &__container {
        position: relative;
    }

    &__top {
        margin-bottom: 30px;
        @include flex($alignItems: flex-end, $justifyContent: space-between);
    }

    &__header {
        @include flex($direction: column);
    }

    &__title {
        @extend %homeTitle;
    }

    &__subtitle {
        @extend %homeSubtitle;
        margin-left: 50px;
    }

    &__link {
        @include flex($direction: column, $justifyContent: flex-end);
        height: 250px!important;
        padding: 30px 50px 30px 25px;
        box-sizing: border-box;
        background-color: $color-light; 
        color: $color-dark;
        transition: all $duration ease-in-out!important;

        &:hover, &:focus {
            background-color: $color-gray;
            color: $color-dark;

            .home-events__date span {
                background-color: $color-third;
                width: 30px;
            }
        }
    }

    &__category {
        font-size: toRem(14);
        font-weight: 400;
        line-height: toRem(20);
        letter-spacing: 1.4px;
        text-transform: uppercase;
    }

    &__title--small {
        @extend %removeMargin;
        font-family: $font-family--heading;
        font-size: toRem(23);
        font-weight: 700;
        line-height: toRem(30);
    }

    &__date {
        font-size: toRem(20);
        font-weight: 700;
        line-height: toRem(20);
        letter-spacing: 2px;
        text-transform: uppercase;
        display: flex;
        margin: 15px 0;

        span {
            display: inline-flex;
            background-color: $color-main;
            border-radius: 0px 20px 20px 0px;
            @include size(15px);
            margin-right: 10px;
            transition: all $duration ease-in-out;
        }
    }

    &__buttons {
        @include flex($direction: row-reverse, $justifyContent: flex-start);
        gap: 30px;
        margin-top: 30px
    }

    &__button {
        @extend %button;
        width: 300px;

        &:last-child {
            @extend %button;
            border-color: $color-third;
    
            &::before,
            &::after {
                background-color: $color-third;
            }
    
            &:hover,
            &:focus {
                border-color: $color-dark;
            }
        }
    }

    &__navigation {
        display: flex;
        gap: 20px;
        margin-bottom: 10px;

        &--prev, &--next {
            // Enlever style par défaut des flèches
            @include size(45px!important, 40px!important);
            @include position(relative!important, $top: unset!important, $left: unset!important, $bottom: unset!important, $right:unset!important);
            margin-top: 0!important;
            background-color: transparent;
            border: 1px solid $color-main;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;

            &:after {
                display: none;
            }

            svg {
                fill: $color-dark;
                @include size(100%);
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                border-color: $color-second;
            }
        }

        &--prev {
            border-radius: 50px 0px 0px 50px;
        }

        &--next {
            border-radius: 0px 50px 50px 0px;
        }
    }
}

//======================================================================================================
// Loisirs Nautiques
//======================================================================================================

.loisirs {
    position: relative;

    &__header {
        @include flex($direction: column, $alignItems: center);
        @include position(absolute, $top: 60px, $left: 50%);
        transform: translateX(-50%);
        color: $color-white;
        z-index: 1;
    }

    &__title {
        @extend %homeTitle;
    }

    &__subtitle {
        @extend %homeSubtitle;
    }

    &__items {
        width: 100%;
        display: flex;
    }

    &__item {
        width: 25%;
        height: 500px;
        position: relative;
        transition: $duration;

        &:before, &:after {
            @include position(absolute, $top: 0, $left: 0);
            content: '';
            @include size(100%);
            transition: $duration;
        }

        &::before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.24) 31.98%, rgba(0, 0, 0, 0) 56.47%, rgba(0, 0, 0, 0.075) 77.33%, rgba(0, 0, 0, 0.24) 86.24%, rgba(0, 0, 0, 0.24) 100%);
        }

        &::after {
            background: rgba($color-dark, .4);
            opacity: 0;
        }

        &:hover,
        &:focus {
            width: 40%;

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }

            .loisirs__title--small {
                column-gap: 10px;
            }
        }
    }

    &__image {
        @include size(100%);
        object-fit: cover;
    }

    &__title--small {
        @include position(absolute, $left: 50%, $bottom: 30px);
        @include flex($alignItems: center, $justifyContent: center);
        transform: translateX(-50%);
        width: 100%;
        z-index: 1;
        column-gap: 0;
        font-size: $font-size--text-medium;
        line-height: 1.25em;
        font-weight: 500;
        color: $color-white;
        letter-spacing: .05em;
        text-transform: uppercase;
        text-align: center;
        padding: 0 5%;
        margin: 0;
        transition: $duration;

        svg {
            @include size(14px);
            margin-left: 6px;
            fill: $color-white;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .sticky {
        top: 285px;
    }

    .home-slideshow {
        .slideshow {
            height: 525px;
        }
    }

    .news {
        padding: 100px 0 90px;

        &:before {
            left: calc(50% + 370px);
            top: -95px;
        }
    
        &:after {
            left: calc(50% - 965px);
            bottom: -43px;
        }
    
        &__content {
            row-gap: 20px;
        }
    
        &__link {
            &--big {
                column-gap: 30px;
            }
    
            &--small {
                width: 310px;
            }
        }
    
        &__image {
            &--big {
                @include size(560px, 255px);
            }
    
            &--small {
                height: 155px
            }
        }
    
        .notimg {
            &--big {
                @include size(560px, 255px);
            }
    
            &--small {
                height: 155px
            }
        }
    
        &__infos {
            &--big {
                margin-top: 35px;
            }
        }
    
        &__bottom {
            gap: 20px;
        }
    
        &__smallitems {
            column-gap: 20px;
        }
    
        &__button {
            width: 280px;
        }
    }

    .escale {
        padding: 100px 0;

        &:before {
            left: calc(50% - 750px);
            bottom: -40px;
        }
    
        &__left {
            gap: 20px;
            min-width: 580px;
        }

        &__image {
            &--big {
                @include size(450px, 310px);
            }

            &--small {
                @include size(280px, 215px);
            }

            img {
                @include size(100%);
                object-fit: cover;
            }
        }
    
        &__bottom {
            gap: 20px;
        }

        &__header {
            margin-left: -50px;
        }

        &__title {
            margin-left: 0;
        }

        &__subtitle {
            margin-left: -23px;
        }
    
        &__text {
            width: 310px;
            margin: 100px 0 0 50px;
            font-size: toRem(16);
            line-height: toRem(25);
    
            &:before {
                @include size(570px, 330px);
                left: -130px;
                top: -40px;
            }
        }
    
        &__button {
            width: 280px;
        }
    }
    
    .home-events {
        padding: 100px 0;
    
        &:before {
            left: calc(50% + 250px);
        }
    
        &:after {
            left: calc(50% - 955px);
            bottom: -25px;
        }
    
        &__buttons {
            gap: 20px;
            margin-top: 20px
        }
    
        &__button {
            width: 280px;
        }
    }

    .loisirs {
        &__item {
            height: 450px;
        }

        &__title--small {
            font-size: toRem(16);
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .sticky {
        position: absolute;
        background-color: $color-second;
        top: 0;
        width: 100vw;
        z-index: 10;
        
        &__container {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 0;
            max-width: $small;
            height: 55px;
            padding: 0 ($gl-gutter-md * 0.5);
            margin: 0 auto;
        }
    
        &__link {
            @include size(unset);
            padding-left: 0;
    
            p {
                min-width: unset;
                opacity: 1;
            }

            &:first-child {
                margin-left: -10px;
            }
    
            &--visible {
                width: unset;
    
                &.hidden {
                    width: unset;
    
                    p {
                        opacity: 1;
                    }
                }
            }
    
            &:hover, &:focus {
                background-color: transparent;
                width: unset;
            }
        }
    }

    .home-slideshow {
        position: relative;
        margin-top: 55px;

        .slideshow {
            height: 530px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }
    }

    .news {
        padding: 80px 0;
    
        &:before {
            @include size(282px, 465px);
            background-size: 282px;
            left: calc(50% + 242px);
            top: 35px;
        }
    
        &:after {
            // bottom: -102px;
            // left: calc(50% - 845px);

            content: none;
        }
    
        &__subtitle {
            margin-left: 40px;
        }
    
        &__link {
            &--big {
                column-gap: 20px;
            }
    
            &--small {
                width: 300px;
            }
        }
    
        &__image {
            &--big {
                @include size(320px, 200px);
            }
    
            &--small {
                @include size(100%, 155px);
            }
        }
    
        .notimg {
            &--big {
                @include size(320px, 200px);
            }
    
            &--small {
                @include size(100%, 155px);
            }
        }
    
        &__infos {
            &--big {
                width: 280px;
                margin-top: 25px;
            }
        }
    
        &__category {
            &--big {
                font-size: toRem(16);
                line-height: toRem(20);
                letter-spacing: 1.6px;
            }
    
            &--small {
                font-size: toRem(14);
                bottom: 10px;
            }
        }
    
        &__titlep {
            &--big {
                font-size: toRem(23);
                line-height: toRem(30);
            }
    
            &--small {
                font-size: toRem(18);
                line-height: toRem(25);
            }
        }
    
        &__text {
            font-size: toRem(14);
            line-height: toRem(20);

            &--small {
                margin-top: 5px;
            }
        }
    
        &__bottom {
            flex-direction: column;
            align-items: flex-end;
            gap: 30px;
        }

        &__buttons {
            flex-direction: row;
            flex-wrap: wrap;
        }
    
        &__button {
            width: 250px;

            &--nl {
                width: 280px;
            }
        }
    }

    .escale {
        padding: 205px 0 130px;
        position: relative;
    
        &:before {
            @include size(415px, 461px);
            background-size: 415px;
            bottom: 0;
            left: calc(50% - 575px);
        }
    
        &__left {
            min-width: unset;
        }
    
        &__bottom {
            flex-direction: row;
            align-items: flex-end;
            margin-left: 90px;
        }
    
        &__header {
            margin: 0;
            @include position(absolute, $top: 92px, $left: calc(50% + -23px));
        }

        &__image {
            &--big {
                @include size(310px, 225px);
            }

            &--small {
                @include size(250px, 190px);
            }
        }
    
        &__subtitle {
            margin-left: -38px;
        }
    
        &__text {
            font-size: toRem(14);
            line-height: toRem(22);
            width: 250px;
            margin: 60px 0 0 -250px;
            position: relative;
    
            &:before {
                @include size(755px, 310px);
                top: -30px;
            }
        }
    
        &__button {
            @extend %button;
            width: 260px;
        }
    }

    .home-events {
        padding: 85px 0;
    
        &:before {
            left: calc(50% + 100px);
        }
    
        &:after {
            @include size(724px, 308px);
            background-size: 724px;
            bottom: -17px;
            left: calc(50% - 814px);
        }
    
        &__button {
            width: 250px;
        }
    }

    .loisirs {
        position: relative;

        &__items {
            flex-wrap: wrap;
        }
    
        &__item {
            width: 50%;
            height: 350px;    
            
            &::before {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
            }

            &::after {
                display: none;
            }

            &:hover,
            &:focus {
                width: 50%;

                &::before {
                    background-color: rgba($color-dark, .5);
                    opacity: 1;
                }
            }
        }
    
        &__title--small {
            bottom: 20px;
            font-size: toRem(20);
            letter-spacing: 1px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .sticky {
        &__container {
            max-width: 90%;
            padding: 0 ($gl-gutter-xs * 0.5);
        }
    }

    .home-slideshow {
        .slideshow {
            height: auto;

            &__container {
                aspect-ratio: 1;
                width: 100%;
                height: auto;
                max-height: 530px;
            }
        }
    }

    .news {
        padding: 40px 0 60px;
    
        &:before, &:after {
            display: none;
        }
        
        &__link {
            &--big {
                flex-direction: column;
                width: 100%;
            }

            &--small {
                width: 100%;
            }
        }

        &__bottom {
            align-items: flex-start;
            gap: 10px;
        }

        &__smallitems {
            flex-direction: column;
            gap: 20px;
        }

        &__image--big {
            width: 100%;
        }

        &__infos--big {
            width: 100%;
            margin-top: 10px;
        }

        &__category--big {
            font-size: toRem(14);
            line-height: toRem(20);
            letter-spacing: 1.4px;
        }

        &__titlep--big {
            margin: 7px 0;
        }

        &__buttons {
            margin-top: 20px;
        }

        &__button {
            width: 300px;

            &--nl {
                width: 300px;
            }
        }
    }
    
    .escale {
        padding: 165px 0 215px;

        &:before {
            display: none;
        }

        &__container {
            flex-direction: column;
            gap: 75px;
        }

        &__header {
            top: 60px;
            left: 50%;
            transform: translateX(-50%);
            align-items: center;
            width: 100%;
        }

        &__subtitle {
            font-size: toRem(22);
            margin-left: 0;
        }

        &__image {
            @include size(100%, 210px);
        }

        &__left {
            width: 100%;
            position: unset;
        }

        &__bottom {
            flex-direction: column;
            margin-left: 0;
        }

        &__text {
            width: 100%;
            margin: 0;

            &:before {
                height: calc(100% + 90px);
                top: -45px;
                left: -80px;
            }
        }

        &__button {
            @include position(absolute, $left: 50%, $bottom: 60px);
            transform: translateX(-50%);
        }
    }
    
    .home-events {
        padding: 50px 0 60px;

        &:before, &:after {
            display: none;
        }

        &__top {
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
            margin-bottom: 20px;
        }

        &__subtitle {
            margin-left: 30px;
        }
        
        &__navigation {
            margin-bottom: 0;
        }

        &__buttons {
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }
    
    .loisirs {
        &__header {
            position: relative;
            top: 0;
            margin-bottom: 30px;
            color: $color-dark;
        }
    
        &__item {
            width: 100%;
            height: 250px;    

            &:hover,
            &:focus {
                width: 100%;
            }
        }
    }
}


// 475
@media screen and (max-width: 475px) {
    .sticky {
        &__link {
            &:nth-child(1) p, &:nth-child(2) p {
                display: none;
            }

            &:nth-child(1) {
                margin-left: -7px;
            }
        }
    }
}