@keyframes headerFixed {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}

.header {
    min-height: 120px;

    &__container {
        @include size(unset, fit-content);
        position: relative;
        z-index: 15;
        background-color: #0D1C37;
        height: 120px;
        transition: background-color $duration ease-in-out;

        > .container {
            @include flex($justifyContent: space-between);
        }
    }

    &__link {
        display: flex;
        width: 200px;
        position: relative;
        z-index: 2;
        transition: all $duration ease-in-out;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%, 230px);
            content: "";
            background: $color-dark;
            border-radius: 0px 0px 150px 150px;
            z-index: -1;
            transition: all $duration ease-in-out;
        }

        svg {
            fill: $color-white;
            @include size(140px);
            margin: 35px auto 0;
            transition: all $duration ease-in-out;
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
        @include size(0);
    }

    &__content {
        display: flex;
        gap: 31px;
    }

    &--fixed {
        .header {
            &__container {
                @include position(fixed, $top: 0, $left: 0, $bottom: 0, $right:0);
                animation: 1s headerFixed;
                height: 90px;
            }

            &__link {
                width: 190px;

                svg {
                    @include size(110px);
                    margin-top: 30px;
                }

                &:before {
                    height: 180px;
                }
            }
        }

        .nav-main {
            .menu {
                &__item {
                    &:before {
                        height: 70px;
                    }
                }

                &__link {
                    padding-bottom: 15px;
                    height: 85px;
                }
            }

            .submenu {
                padding-top: 10px;
            }
        }

        .tools {
            margin-top: 25px;

            &__item {
                border-color: $color-third;
    
                &::before,
                &::after {
                    background-color: $color-third;
                }
            }
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    gap: 10px;
    margin-top: 40px;
    transition: all $duration ease-in-out;
    
    #accessconfig {
        @include size(40px);
    }

    &__item {
        @extend %button;
        color: $color-white;
        border-color: $color-white;
        @include size(40px);
        min-height: unset;
        padding: 0!important;
        margin: 0!important;

        svg {
            @include size(100%);
            display: flex;
            fill: $color-white;
            transition: $duration;
        }

        &::before,
        &::after {
            background-color: $color-white;
        }

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: $color-second;

            svg {
                fill: $color-white;
            }
        }

        &--search--disabled {
            pointer-events: none;
            opacity: .5;
        }
    }

    &__translate {
        position: relative;

        &__wrapper {
            @include absolute($left: 50%, $top: 65px);
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 5px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-dark;
            border-radius: $border-radius;
            transform: translateX(-50%);
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                @include absolute($top: -6px, $left: 50%);
                display: block;
                @include size(30px);
                background-color: $color-dark;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family--heading;
                font-size: toRem(16);
                font-weight: 400;
                line-height: toRem(20);
                color: $color-white;
        
                &:hover, &:focus {
                    color: $color-second;
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    color: $color-second;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            @include absolute($bottom: -10px, $left: 50%);
            @include flex($alignItems: center, $justifyContent: center);
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-second;
            border-radius: $border-radius--round;
            border: none;

            svg {
                @include size(26px);
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-third;

                svg {
                    fill: $color-dark;
                }
            }
        }

        a.glink.gt-current-lang {
            font-weight: normal;
        }
    }

}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        min-height: 95px;

        &__container {
            height: 95px;
        }

        &__link {
            width: 170px;

            svg {
                @include size(120px);
                margin: 30px auto 0;
            }

            &:before {
                height: 200px;
            }
        }

        &__content {
            gap: 27px;
        }

        &--fixed {
            .header {
                &__container {
                    height: 95px;
                }

                &__link {
                    width: 170px;
                }
            }

            .nav-main {
                .menu {
                    &__item::before {
                        height: 65px;
                    }
    
                    &__link {
                        height: 65px;
                        padding-bottom: 0;
                    }
                }

                .submenu {
                    padding-top: 25px;
                }
            }
        }
    }

    .tools {
        margin-top: 25px;
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        position: absolute;
        padding-top: 55px;
        width: 100%;
        min-height: unset;

        &__container {
            position: relative;
            height: 80px;
        }

        &__link {
            z-index: 1;
        }

        &__content {
            gap: 0;
            margin: 20px 0 0;
        }
    }

    .tools {
        margin-top: 0;
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        &__content {
            gap: 10px;
        }
    }
}

// 540
@media screen and (max-width: 540px) {
    .header {
        &__link {
            width: 100px;

            &:before {
                height: 120px;
            }

            svg {
                @include size(70px, 72px);
                margin: 15px auto 0;
            }
        }

        &__content {
            gap: 5px;
        }
    }

    .tools {
        gap: 5px;
    }
}
