.nav-main {
    position: relative;
    height: fit-content;
    z-index: 1;

    &:before {
        @include position(absolute, $top: 0, $left: calc(50% - 1040px));
        @include size(calc(100% + 1255px), 0);
        content: "";
        min-height: 0;
        background-color: rgba(13, 28, 55, 0.9);
        z-index: 0;
        transition: all $duration ease-in-out;
    }

    &.js-open-submenu {
        &:before {
            height: calc(100% + 75px);
            min-height: 325px;
        }
    }

    // Main menu
    .menu {
        display: flex;
        gap: 67px;

        &__item {
            @include flex($direction: column);
            position: relative;
            height: fit-content;

            &:before {
                @include position(absolute, $top: 0, $left: -30px);
                @include size(15px, 80px);
                content: "";
                background-color: $color-third;
                border-radius: 0 0 20px 20px;
                z-index: 0;
                transition: all $duration ease-in-out;
            }
            
            &:nth-child(1) {
                width: 145px;
            }

            &:nth-child(2) {
                width: 180px;
            }

            &:nth-child(3) {
                width: 180px;
            }

            &.menu-item-has-children.js-open-submenu {
                &:before {
                    height: 100%;
                }

                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                    height: 100%;

                    &__item {
                        opacity: 1;
                        transform: none;
                        transition-duration: .5s;
                        transition-timing-function: cubic-bezier(0,0,.2,1);
                        
                        &:first-child {
                            transition-delay: .25s;
                        }

                        &:nth-child(2) {
                            transition-delay: .35s;
                        }

                        &:nth-child(3) {
                            transition-delay: .45s;
                        }

                        &:nth-child(4) {
                            transition-delay: .55s;
                        }

                        &:nth-child(5) {
                            transition-delay: .65s;
                        }

                        &:nth-child(6) {
                            transition-delay: .75s;
                        }

                        &:nth-child(7) {
                            transition-delay: .85s;
                        }

                        &:nth-child(8) {
                            transition-delay: .95s;
                        }

                        &:nth-child(9) {
                            transition-delay: 1.05s;
                        }

                        &:nth-child(10) {
                            transition-delay: 1.15s;
                        }

                        &:nth-child(11) {
                            transition-delay: .75s;
                        }

                        &:nth-child(12) {
                            transition-delay: .8s;
                        }
                    }
                }

            }
        }   
        
        &__link {
            @include flex($alignItems: flex-start, $direction: column, $justifyContent: flex-end);
            height: 80px;
            color: $color-white;
            text-transform: uppercase;
            font-family: $font-family--heading;
            font-weight: 800;
            font-size: toRem(18);
            line-height: toRem(25);
            letter-spacing: 0.9px;
            transition: all $duration ease-in-out;

            span {
                font-weight: 400;
            }
        }
    }

    // Sub menu
    .submenu {
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        height: 0;
        pointer-events: none;
        padding-top: 25px;
        transition: all $duration ease-in-out;

        &__nav {
            @include flex($direction: column);
            gap: 10px;
            padding: 0;
        }

        &__item {
            width: auto;
            margin: 0;  
            opacity: 0;
            transform: translateX(-40px);
            transition: opacity .3s cubic-bezier(.55,.055,.675,.19),transform .3s cubic-bezier(.55,.055,.675,.19);
            transition-delay: .35s;
        }

        &__link {
            @include flex($alignItems: flex-start);
            gap: 10px;
            color: $color-white;
            font-family: $font-family;
            font-weight: 700;
            font-size: toRem(16);
            line-height: toRem(25);
            min-height: 25px;
            transition: all $duration ease-in-out;

            svg {
                @include size(12px);
                min-width: 12px;
                fill: $color-white;
                margin-top: 7px;
            }

            &:hover, &:focus {
                transform: translateX(15px);
            }
        }
        
        &__close {
            display: none;
        }
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }

}
//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    .nav-main {
        &.js-open-submenu {
            &:before {
                height: calc(100% + 50px);
                min-height: 285px;
            }
        }

        .menu {
            gap: 41px;
    
            &__item {
                &:before {
                    @include size(10px, 65px);
                    left: -25px;
                }
                
                &:nth-child(1) {
                    width: 115px;
                }
    
                &:nth-child(2) {
                    width: 145px;
                }
    
                &:nth-child(3) {
                    width: 140px;
                }
            }   
            
            &__link {
                height: 65px;
                font-size: toRem(14);
                line-height: toRem(20);
                letter-spacing: 0.7px;
            }
        }

        .submenu {
            &__link {
                &:hover, &:focus {
                    transform: translateX(10px);
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        &:before {
            display: none;
        }

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-dark;
            padding: 40px 0;
            transition: left $duration $timing;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            @include size(130px, 40px);
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: 1px solid $color-third;
            border-radius: 0;
            font-family: $font-family--heading;
            font-size: toRem(18);
            font-weight: 600;
            line-height: toRem(20);
            letter-spacing: 1.8px;
            color: $color-white;
            text-transform: uppercase;
            cursor: pointer;

            &:hover, &:focus {
                color: $color-white;
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: 0;
            display: block;
            @include size(18px, 2px);
            background-color: $color-white;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                @include size(100%);
                background-color: $color-white;
            }

            &:before {
                top: 7px;
            }

            &:after {
                top: -7px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: $btn-border-width $btn-border-style $color-second;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-second;
                border-color: $color-second;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;
            gap: 15px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: fit-content!important;
                position: unset;

                &:before {
                    display: none;
                }
    
                &.menu-item-has-children {

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                            height: 100vh;
                        }
    
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-white;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0 20px 20px;
                color: $color-white;
                transition: none;
                font-size: toRem(18);
                line-height: toRem(25);
                letter-spacing: 0.9px;
                position: relative;

                &:before {
                    @include position(absolute, $top: 27px, $left: -40px);
                    @include size(50px, 10px);
                    content: "";
                    background-color: $color-third;
                    border-radius: 0 20px 20px 0;
                    z-index: 0;
                    transition: all $duration ease-in-out;
                }
            }
        }

        // Sub menu
        .submenu {
            position: absolute;
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-dark;
            transition: {
                property: visibility, pointer-events, right;
                duration: $duration;
                timing-function: $timing;
            }
            opacity: 1;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: $btn-border-width $btn-border-style $color-second;
                order: 1;

                &:hover,
                &:focus {
                    background-color: $color-second;
                    border-color: $color-second;

                    svg {
                        fill: none;
                        stroke: $color-dark;
                    }
                }

                &__mobile {
                    display: block;
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                }

                &__icon, span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 20px 40px 0;
                gap: 20px;
                margin: 0;
                overflow-y: auto;
                order: 2;
            }

            &__link {
                color: $color-white;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .nav-main {
        > .container {
            margin: 0;
            padding: 0;
            max-width: unset;
        }

        &__close {
            margin: 40px 40px 0 auto;
        }
    }
}

// 540
@media screen and (max-width: 540px) {
    .nav-main {
        &__button {
            width: 40px;
    
            span:last-child {
                display: none;
            }
        }
    }
}
