.cover {
    position: relative;
    
    &:before {
        @include position(absolute, $top: 0, $left: 0);
        @include size(100%, 230px);
        content: "";
        background-color: #0D1C37;
    }

    &__image-wrapper {
        width: 1230px;
        height: 440px;
        margin: 0 auto;
        background-color: $color-bg--image;
        position: relative;

        // &:before {
        //     @include position(absolute, $top: 0, $left: 0);
        //     @include size(100%);
        //     content: "";
        //     background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.35) 47.92%, rgba(0, 0, 0, 0) 100%);
        //     z-index: 0;
        //     transition: all $duration ease-in-out;
        // }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background-color: $color-white;
    }

    &__title {
        margin: 0;
    }

    &__lead-wrapper {
        margin: 15px 0 0;
        font-size: toRem(20);
        font-weight: 700;
        line-height: toRem(25);
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &:before {
            height: 157px;
        }

        &__image-wrapper {
            width: 990px;
            height: 315px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        margin-top: 80px;

        &:before {
            height: 120px;
        }

        &__image-wrapper {
            width: 665px;
            height: 240px;
        }

        &__title {
            font-size: toRem(40);
            line-height: toRem(42);
        }

        &__intro {
            font-size: toRem(18);
            line-height: toRem(22);
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .cover {
        &__image-wrapper {
            aspect-ratio: 1;
            width: 100%;
            height: auto;
            // max-height: 530px;
            max-height: 230px;
            background-color: $color-white;
        }

        &__title {
            font-size: toRem(34);
            line-height: toRem(38);
        }
    }
}
