//======================================================================================================
// Bloc Content (textual content) - alert, paragraph, question-answer, quote
//======================================================================================================
.bloc-content {
    @include flex($alignItems: center);
    padding: 30px;
    margin: 30px 0;

    &__container {
        flex: 1;
    }

    &__title {
        font-family: $font-family--heading;
        font-weight: $font-weight;
        font-size: $font-size--text-medium ;
        text-transform: uppercase;
    }

    &__content {
        margin-top: 5px;
    }

    &--alert {
        background-color: $color-flexible-alert;
        border-radius: $border-radius;
        color: $color-white;

        svg {
            @include size(57px, 54px);
            margin-right: 30px;
            fill: $color-white;
        }
    }

    &--paragraph,
    &--question_answer {
        display: inherit;
        padding: 0;

        p {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }

        a {
            display: inline-block;
            text-decoration: underline;
            color: $color-dark;

            &[target="_blank"]::after {
                content: "\f504";
                font: normal 1rem/1 dashicons;
                float: right;
                position: relative;
                top: 2px;
                margin-left: 2px;
            }

            &:hover,S
            &:active,
            &:focus {
                text-decoration: none;
                color: $color-dark;
            }
        }

        ul, ol {
            line-height: 22px;
            padding-left: 20px;
        }

        ul {
            li {
                position: relative;

                &::before {
                    @include position(absolute, $top: 10px, $left: -20px);
                    content: "";
                    @include size(8px);
                    background-color: $color-third;
                    border-radius: 50px;
                }

                ul {
                    li {
                        &::before {
                            background-color: $color-dark;
                        }

                        ul {
                            li {
                                &::before {
                                    background-color: $color-second;
                                }
                            }
                        }
                    }
                }
            }
        }


        // For site map
        ul.nav-grandchild {
            li::before {
                color: $color-gray;
            }
        }

        ol {
            list-style-type: decimal;
        }

        & > ul, & > ol {
            margin: 20px 0;
        }

        &.border {
            padding: 15px;
            border-left: 15px solid;

            > svg {
                display: none;
            }

            &.primary {
                border-color: $color-main;
            }

            &.secondary {
                border-color: $color-second;
            }

            &.neutral {
                border-color: $color-bg--neutral;
            }
        }

        &.background {
            position: relative;
            display: flex;
            align-items: flex-start;
            color: $color-dark;
            padding: 30px;

            &::before {
                content: '';
                width: 10000px;
                height: 100%;
                position: absolute;
                top: 0;
                left: -3000px;
                z-index: -1;
            }

            > svg {
                width: 80px;
                height: 80px;
                position: relative;
                display: flex;
                fill: $color-text;
                margin-right: 20px;
                margin-left: -15px;

                + .bloc-content__text {
                    position: relative;

                    &::before {
                        content: '';
                        width: 5000px;
                        height: 80px;
                        position: absolute;
                        top: 0;
                        right: calc(100% + 20px);
                        z-index: -1;
                        background-color: $color-white;
                        border-radius: 0 100px 100px 0;
                    }
                }
            }

            &.primary {
                &::before {
                    background: $color-main;
                }

                ul {
                    li {
                        &::before {
                            color: $color-dark;
                        }
                    }
                }

                a {
                    color: $color-dark;
                }
            }

            &.secondary {
                color: $color-text;

                &::before {
                    background: $color-second;
                }

                ul {
                    li {
                        &::before {
                            color: $color-text;
                        }
                    }
                }

                a {
                    color: $color-text;
                }
            }

            &.neutral {
                color: $color-text;

                &::before {
                    background: $color-bg--neutral;
                }

                > svg {
                    fill: $color-text;
                }

                ul {
                    li {
                        &::before {
                            color: $color-text;
                        }
                    }
                }

                a {
                    color: $color-text;
                }
            }

            .bloc-content {
                &__text {
                    flex: 1;
                }
            }

        }

    }

    &--quote {
        justify-content: center;
        align-items: flex-start;
        padding: 0;

        .bloc-content__container {
            max-width: 890px;
            padding: 10px 60px;
        }

        .bloc-content__content {
            position: relative;
            margin: 0;

            svg {
                content: "";
                position: absolute;
                @include size(40px, 30px);
                fill: $color-main;

                &:first-child {
                    top: 0;
                    left: -60px;
                }

                &:last-child {
                    right: -60px;
                    bottom: 0;
                }
            }

            blockquote {
                padding: 0;
                margin: 0;
                border-left: none;
                font-size: $font-size--text;
            }

            p {
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .bloc-content__author {
            margin-top: 10px;
            text-align: right;
            font-weight: $font-weight-medium;
            color: $color-dark;
        }

        .bloc-content__source {
            @include flex($justifyContent: flex-end);
            color: $color-main;
            text-decoration: underline;
        }
    }

    &.--background1 {
        background-color: $color-main;
        color: $color-white;
    }

    &.--background2 {
        background-color: $color-second;
        color: $color-white;
    }

    &.--background3 {
        background-color: $color-third;
    }

    &.--border1 {
        padding: 10px 20px;
        border-left: 10px solid $color-main;
    }

    &.--border2 {
        padding: 10px 20px;
        border-left: 10px solid $color-second;
    }

    &.--border3 {
        padding: 10px 20px;
        border-left: 10px solid $color-third;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .bloc-content--paragraph.background,
    .bloc-content--question_answer.background {
        padding: 20px;
    }

}


// 640
@media screen and (max-width: $small) {

    .bloc-content {
        padding: 20px;
        margin: 20px 0;

        &--alert {
            flex-direction: column;
            align-items: flex-start;

            svg {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        &--paragraph {
            &.background {
                flex-direction: column;
                align-items: flex-start;

                > svg + .bloc-content__text {
                    margin-top: 20px;

                    &::before {
                        top: -100px;
                        right: calc(100% - 80px);
                    }
                }
            }
        }

        &--quote,
        &--paragraph,
        &--question_answer {
            padding: 0;
        }
    }
}
