//=================================================================================================
// Home
//=================================================================================================

// Usage : @extend %homeTitle;
%homeTitle {
    @extend %removeMargin;
    font-family: $font-family--heading;
    font-size: toRem(55);
    font-weight: 700;
    line-height: toRem(60);
    letter-spacing: 2.75px;
    text-transform: uppercase;
}

// Usage : @extend %homeSubtitle;
%homeSubtitle {
    @extend %removeMargin;
    font-family: $font-family--heading;
    font-size: toRem(30);
    font-weight: 400;
    line-height: toRem(35);
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

// Usage : @extend %button;
%button {
    min-height: 80px;
    position: relative;
    @include flex($alignItems: center, $justifyContent: center);
    font-family: $font-family--heading;
    font-size: toRem(14);
    font-weight: 600;
    line-height: toRem(20);
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: $color-dark;
    text-align: center;
    background: transparent;
    border: $btn-border-width $btn-border-style $color-main;
    transition: $duration;

    &::before,
    &::after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        background-color: $color-main;
        transition: $duration;
    }

    &::before {
        top: -1px;
        right: 0;
    }

    &::after {
        right: unset;
        left: 0;
        bottom: -1px;
    }

    &:hover,
    &:focus {
        color: $color-text;
        background-color: transparent;
        border: 1px solid $color-second;
        transition: .3s;

        &::before,
        &::after {
            width: 0;
            transition: .3s .3s ease-out;
        }
    }
}

// Usage : @extend %removeMargin;
%removeMargin {
    margin: 0;
    padding: 0;
}

//=================================================================================================
// Clearfix
//=================================================================================================

// Usage : @extend %clearfix;
%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


//=================================================================================================
// Visually hide an element
//=================================================================================================

// Usage : @extend %visuallyhidden
%visuallyhidden {
    width: 1px;
    height: 1px;
    position: absolute;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    white-space: nowrap;
}


//=================================================================================================
// Centered block
//=================================================================================================

// Usage : @extend %center
%center {
    display: block;
    margin-left: auto;
    margin-right: auto; 
}


//=================================================================================================
// Centered container
//=================================================================================================

// Usage : @extend %container
%container {
    max-width: $large;
    padding: 0 ($gl-gutter * 0.5);
    margin: 0 auto;
}


//=================================================================================================
// Cacul for positionning element left or right container
//=================================================================================================

// Usage : @extend %SpaceFull-to-Container--left
%SpaceFull-to-Container--left {
    left: calc((100% - ($large - $gl-gutter))/2);
}

// Usage : @extend %SpaceFull-to-Container--right
%SpaceFull-to-Container--right {
    right: calc((100% - ($large - $gl-gutter))/2);
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    %container {
        max-width: $medium;
        padding: 0 ($gl-gutter-md * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - ($medium - $gl-gutter-md))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($medium - $gl-gutter-md))/2);
    }
}


// 960
@media screen and (max-width: $medium) {

    %container {
        max-width: $small;
        padding: 0 ($gl-gutter-sm * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - ($small - $gl-gutter-sm))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($small - $gl-gutter-sm))/2);
    }

    %homeTitle {
        font-size: toRem(40);
        line-height: toRem(50);
        letter-spacing: 2px;
    }

    %homeSubtitle {
        font-size: toRem(25);
        line-height: toRem(30);
        letter-spacing: 1.25px;
    }

    %button {
        min-height: 70px;
    }
}


// 640
@media screen and (max-width: $small) {

    %container {
        max-width: 90%;
        padding: 0 ($gl-gutter-xs * 0.5);
        margin: 0 auto;
    }
    
    %SpaceFull-to-Container--left {
        left: calc((100% - ($x-small - $gl-gutter-xs))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($x-small - $gl-gutter-xs))/2);
    }
}
