.footer {
    background-color: $color-dark;
    padding: 80px 0 100px;
    position: relative;

    &:before {
        @include position(absolute, $left: calc(50% + -660px), $bottom: 0);
        @include size(1450px, 260px);
        content: "";
        background-image: url(/wp-content/themes/la-grande-motte-port/assets/src/images/sprite-images/home/capitainerie.svg);
        background-repeat: no-repeat;
        z-index: 0;
        transition: all $duration ease-in-out;
    }

    &__container {
        @include flex($direction: column);
    }

    &__top,
    &__buttons {
        @include flex($alignItems: center);
    }

    &__top {
        gap: 100px;
    }

    &__buttons {
        column-gap: 100px;
        margin-top: 40px;
    }

    &__logo {
        width: 300px;
        @include flex($justifyContent: center);

        svg {
            @include size(125px);
        }
    }

    &__mairie,
    &__horaires {
        width: 300px;
        @include flex($direction: column, $alignItems: flex-start);
    }

    &__title {
        font-family: $font-family--heading;
        font-size: toRem(23);
        line-height: toRem(30);
        font-weight: 700;
        color: $color-white;
        margin: 0;
    }

    &__text {
        color: $color-white;
        margin-top: 15px;
    }

    &__button {
        @extend %button;
        color: $color-white;
        width: 300px;
        border-color: $color-white;

        &::before,
        &::after {
            background-color: $color-white;
        }

        &:hover,
        &:focus {
            color: $color-white;
            border-color: $color-main;
        }

        &--port {
            border-color: $color-second;

            &::before,
            &::after {
                background-color: $color-second;
            }
        }
    }

    &__partenaires {
        @include flex($justifyContent: space-between, $alignItems: center);
        width: 300px;
        margin-top: 30px;
    }

    &__partenaire {
        svg {
            fill: $color-white;
            transition: fill $duration ease-in-out;
        }

        &:nth-child(1) svg {
            @include size(135px, 35px);
        }

        &:nth-child(2) svg {
            @include size(52px, 60px);
        }

        &:nth-child(3) svg {
            @include size(58px, 60px);
        }

        &:hover, &:focus {
            svg {
                fill: $color-second;
            }
        }
    }

    &__menu {
        @include flex($alignItems: center, $justifyContent: space-between);
        margin-top: 60px;
    }

    &__item {
        position: relative;
        @include flex($alignItems: center);

        span {
            @include size(20px, 10px);
            border: 1px solid $color-light;
            border-radius: 20px;
            margin: 0 40px;
            display: inline-flex;
        }

        &:last-child span {
            display: none;
        }
    }

    &__link {
        color: $color-light;

        &:hover,
        &:focus {
            color: $color-second;
        }
    }
}

//======================================================================================================
// RESPONSIVE 
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {
        &:before {
            left: calc(50% - 510px);
        }
    
        &__top {
            gap: 45px;
        }
    
        &__buttons {
            column-gap: 45px;
        }
    
        &__logo {
            width: 260px;
    
            svg {
                @include size(115px);
                margin-right: 10px;
            }
        }
    
        &__mairie,
        &__horaires {
            width: 260px;
        }

        &__text {
            line-height: toRem(20);
        }
    
        &__button {
            width: 260px;
        }
    
        &__partenaires {
            width: 260px;
            margin-top: 25px;
        }
    
        &__partenaire {
            &:nth-child(1) svg {
                @include size(112px, 30px);
            }
    
            &:nth-child(2) svg {
                @include size(44px, 50px);
            }
    
            &:nth-child(3) svg {
                @include size(48px, 42px);
            }
        }
    
        &__item {
            span {
                margin: 0 15px;
            }
        }
    }
}



// 960
@media screen and (max-width: $medium) {
    .footer {
        padding: 80px 0 90px;
        
        &:before {
            @include size(885px, 160px);
            background-size: 885px;
            left: calc(50% - 350px);
        }

        &__top {
            flex-wrap: wrap;
            justify-content: center;
            gap: 60px;
        }

        &__logo {
            width: 100%;

            svg {
                margin-right: 0;
            }
        }

        &__mairie {
            width: 260px;
            align-items: center;
        }

        &__partenaires {
            width: 260px;
            margin-top: 30px;
        }

        &__text, &__title {
            text-align: center;
        }

        &__text {
            font-size: toRem(14);
            line-height: toRem(20); 
        }

        &__buttons {
            margin-top: 30px;
            gap: 25px 70px;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__button {
            width: 250px;
        }

        &__menu {
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px 0;
            margin: 60px 0 0;
            width: 100%;
        }

        &__item {
            font-size: toRem(16);
            line-height: toRem(25);

            span {
                margin: 0 25px;
            }

            &:nth-child(3) span {
                margin: 0 0 0 25px;
            }
        }
    }
}



// 640
@media screen and (max-width: $small) {
    .footer {
        padding: 80px 0 55px;

        &:before {
            @include size(500px, 90px);
            background-size: 500px;
            left: calc(50% - 250px);
        }
        
        &__partenaires {
            @include position(absolute, $top: 625px, $left: 50%);
            transform: translateX(-50%);
        }

        &__buttons {
            flex-direction: column;
            gap: 20px;
        }

        &__button {
            min-height: 60px;
        }

        &__menu {
            margin: 160px auto 0;
            width: 300px;
        }

        &__item {
            font-size: toRem(14);
            line-height: toRem(20);

            span {
                margin: 0 30px;
            }

            &:nth-child(1) span {
                margin: 0 0 0 30px;
            }

            &:nth-child(3) span {
                margin: 0 30px;
            }

            &:nth-child(2), &:nth-child(4) {
                span {
                    display: none;
                }
            }
        }
    }
}